<template>
  <div class="back-button">
    <router-link :to="to"><i class="fa fa-arrow-left"></i>Retour</router-link>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: {
    to: {
      type: Object,
      default: {name: "home"}
    }
  }
}
</script>

<style scoped lang="less">
div.back-button {
  margin: 4px 8px;

  a {
    color: black;
    font-size: 1.3em;
    text-decoration: none;

    & i {
      margin-right: 5px;
    }
  }
}
</style>
